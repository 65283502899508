import React from 'react';
import Anchor from "../../UI/Anchor";

const imgStyles = {
    
  };
const AdvBanner     =   ({textHead,texSubHead,bannerLenders,Style}) =>{
    const textHeadDefault = (textHead)?textHead:"How To Get Started";
    const Lenders     =   bannerLenders.map((lender,index)=>{
        return (
            <Anchor dataId="MID_PARA_NUM" key={index} className={`btn ${Style.firstgoldbtn}`}>
                {lender}
            </Anchor>
        );
    });
    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div className={Style.banner11}>
           <h2>{textHeadDefault}</h2>
           <p></p>
           <h6>{texSubHead}</h6>
           <div className={Style.age_brdr}>
              <div className={Style.arow}>
                 <img src="/PPI_OPC_V1/img/btm_arow.png" alt=""/>
              </div>
              {Lenders}
           </div>
        </div>
     </div>
    )
}
export default AdvBanner;