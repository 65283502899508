import React,{useState} from 'react';
import  ContactStyle from "../../Assets/Contact/Contact.module.scss";
import {useContact} from '../../Hooks/useContact';
import PrivacyPolicyModal from "../Includes/Layouts/PrivacyPolicyModal";
import CookiesModal from "../Includes/Layouts/CookiesModal";

const Contact =   () =>{
    const {contactSubmit,constactLoading} = useContact();
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState(false);
    const [messageValid, setMessageValid] = useState(false);
    const [contactSuccess, setContactSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(email == ""){
            setEmailValid(true); 
        }else if(phone == "" || (phone.replace(/\D/g,'').length == 0)){
            setPhoneValid(true); 
        }else if(message == ""){
            setMessageValid(true);
        }else{
            const txtPhone = phone.replace(/\D/g,'');
            try{
                const response      =   await contactSubmit(
                    phone,
                    email,
                    message
                )
                if(response.data.status === "Success"){
                    setTimeout(
                        () => {
                            setContactSuccess(true)
                            setEmail("");
                            setPhone("");
                            setMessage("");
                        },
                        1000
                    );
                }
            }catch(e){
                console.warn(e);
            }
        }
    }
    const checkPhoneValid= (e) =>{
        var regex = new RegExp("^[0-9]+$");
        var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key)) {
           e.preventDefault();
           return false;
        }
    }
    return (
        <>
            <div>
                <header className={ContactStyle.contact_header}>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-12 text-center">
                                <p><i>Advertorial</i></p>
                            <a><img src="/Contact/img/logo.png" className={`${ContactStyle.animated} logo ${ContactStyle.bounceInDown}`}/></a>
                            </div>
                        </div>
                    </div>
                </header>
                <section className="">
                    <div className="container">
                        <span id="contact_success" className={ContactStyle.contact_success} style={contactSuccess ? { display: "block", marginTop : 25 } : { display: "none" }}>Your email has been sent successfully</span>
                        <div className={`offset-lg-3 col-lg-6 ${ContactStyle.contact_part}`} style={contactSuccess ? { display: "block", marginTop : 25 } : {}}>
                            <h1>Contact Us</h1>
                            <form name="contact_form" id="contact_form"  onSubmit={handleSubmit}>
                        
                                <div className="form-group has-feedback">
                                    <label >Email Address</label>
                                    <input type="email" name="emailid" id="emailid" 
                                        className="form-control h45" placeholder="Email Address"
                                        onChange={(e)=>setEmail(e.target.value)}
                                        value={email}  onBlur={()=>setEmailValid(false)}
                                    />
                                    <span id="email_err" className={ContactStyle.error_msg} style={emailValid ? { display: "block" } : { display: "none" }}>Please Enter Email Address</span>
                                </div>
                                <div className="form-group has-feedback">
                                    <label >Phone Number</label>
                                    <input type="text" name="phonenumber" id="phonenumber" 
                                        className="form-control h45" placeholder="Phone Number"
                                        onChange={(e)=>setPhone(e.target.value)}
                                        value={phone} onKeyPress={(e)=>checkPhoneValid(e)} onBlur={()=>setPhoneValid(false)} maxLength="11"
                                    />
                                    <span id="phone_err" className={ContactStyle.error_msg} style={phoneValid ? { display: "block" } : { display: "none" }}>Please Enter Phone Number</span>
                                </div>
                                <div className="form-group ">
                                <label htmlFor="comment">Message</label>
                                    <textarea className="form-control" rows="5" name="message" id="message" 
                                    placeholder="Message" onChange={(e)=>setMessage(e.target.value)} value={message}  onBlur={()=>setMessageValid(false)}>  
                                    </textarea>
                                    <span id="message_err" className={ContactStyle.error_msg} style={messageValid ? { display: "block" } : { display: "none" }}>Please Enter Message</span>
                                </div>
                                <div className={`col-lg-12 text-center ${ContactStyle.contactButton}`}>
                                <input type="submit" id="contactbutton" className={`btn btn-success ${ContactStyle.send_btn} center-block" value="Submit`} value="Submit"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className={ContactStyle.pb}>
                                <p>DISCLAIMER: The material on this website is intended only to provide a summary and general overview on matters of interest.</p>
                                <p>SOURCES</p>
                                <p><a className="rp_clk" href="https://www.mirror.co.uk/money/millions-customers-can-now-make-23940044" target="_blank">*Millions more customers can now make new PPI claims - could you get up to £40,000? - Mirror Online</a></p>
                                <p><a className="rp_clk" href="https://www.thesun.co.uk/money/14681126/millions-of-customers-new-ppi-claims-bank-ruling/" target="_blank">**Millions of customers can make new PPI claims for up to £40,000 after crucial court ruling (thesun.co.uk)</a></p>
                            </div>
                            <div className={`col-12 ${ContactStyle.end_footer}`}>
                                <div className="row m-0">
                                    <div className="col-lg-3 col-md-3 col-sm-4 col-12 text-center">
                                        <a href="#" data-id="SITE_lOGO"> <img src="/Contact/img/logo.png" alt=""/> </a>
                                    </div>
                                    <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                                        <ul>
                                            <li><a href="javascript:;" className="rp_clk" rel="noreferrer" data-toggle="modal" data-target=".CookiePopup">Privacy Policy</a></li>
                                            <li><a href="javascript:;" className="rp_clk" rel="noreferrer" data-toggle="modal" data-target=".CookiePopup">Cookies Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <PrivacyPolicyModal/>
            <CookiesModal/>
        </>
    )
}
export default Contact;