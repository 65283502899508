const BannerLendersCP_V1= ['Alliance & Leicester',
    'Barclaycard',
    'Black Horse',
    'Capital One',
    'First Direct',
    'First Plus',
    'Goldfish',
    'Halifax Bank of Scotland',
    'HSBC',
    'Halifax Bank of Scotland (HBOS)',
    'J D Williams & Company Ltd (Ambrose Wilson)',
    'Liverpool Victoria Insurance Company Limited',
    'Lloyds',
    'Marbles',
    'Mark & Spencer Bank',
    'MBNA',
    'Sainsbury Bank',
    'Santander',
    'Halifax',
    'HSBC SAR Team',
    'EGG (Barclaycard)',
    'Bradford & Bingley (NRAM)',
    'Barclays',
    'Santander (Abbey)'
];

export {BannerLendersCP_V1};