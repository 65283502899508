import React,{useContext} from 'react';
import { Link } from 'react-router-dom';
import RedirectContext from "../../Contexts/RedirectContext";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import {useDataIngestion} from "../../Hooks/useDataIngestion";

const Anchor     = ({dataId,className,children}) =>{
  const { redirectUrl } = useContext(RedirectContext);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const screenSize = window.innerWidth +" x "+ window.innerHeight;
  const { saveDataIngestion} = useDataIngestion();
  const clickRedirect = async(e) =>{
    e.preventDefault();
    if(visitorParameters.data != null || visitorParameters.data != undefined){
      const pageOpen  = visitorParameters.date;
      const pageClose = new Date();
      const hours   = pageClose.getHours()-pageOpen.getHours();
      const minutes = pageClose.getMinutes()-pageOpen.getMinutes();
      const seconds = pageClose.getSeconds()-pageOpen.getSeconds();
      const time = (seconds + (minutes * 60) + (hours * 60 * 60));
      var min="";
      var sec="";
      var spent="";
      if (time < 60) {
        spent = '00 : ' + time + ' sec';
      } else if (time < 3600) {
        min = (parseInt(time / 60));
        sec = (time % 60);
        spent = min + ' mts : ' + sec + ' sec';
      } else {
        var hur = (parseInt(time / 3600));
        var mnts = (time % 3600);
        min = (parseInt(mnts / 60));
        sec = (mnts % 60);
        spent = hur + ' hrs : ' + min + ' mts : ' + sec + ' sec';
      }
      visitorParameters.data.click_link=dataId;
      visitorParameters.data.redirectUrl=redirectUrl;
      visitorParameters.data.timeSpent=spent;
      visitorParameters.data.screen=screenSize;
      visitorParameters.data.page="2021/05/plevin/ppi_OPC_V1";
      const advPagevClick = await saveDataIngestion(
        "adv_click",
        visitorParameters.visitor_parameters,
        visitorParameters.data,
        visitorParameters.pageName
      );
      if(advPagevClick.data.status==='Success'){
        window.location.href=redirectUrl;
      }
    }

  };
  return (
    <>
        <Link to="#" className={className} data-id={dataId} onClick={clickRedirect}>{children}</Link>
    </>
  )
}
export default Anchor;
