import React,{useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {BannerLendersCP_V1} from '../../constants/Constants';
import  Adv1Style from "../../Assets/Adv1/Adv1.module.scss";
import { VisitorParamsProvider } from "../../Contexts/VisitorParams";
import { RedirectProvider } from "../../Contexts/RedirectContext";
import * as EnvConstants from "../../constants/EnvConstants";
import { useUserAgent } from "../../Hooks/useUserAgent";
import { useRedirection } from "../../Hooks/useRedirection";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import AdvHeader from "../Includes/Adv1/AdvHeader";
import AdvBanner from "../Includes/Adv1/AdvBanner";
import AdvMap from "../Includes/Adv1/AdvMap";
import AdvSidebar from "../Includes/Adv1/AdvSidebar";
import AdvFooter from "../Includes/Adv1/AdvFooter";
import Anchor from "../UI/Anchor";
import CookiesModal from "../Includes/Layouts/CookiesModal";

const Adv1 =   () =>{
    const queryString = useLocation().search;
    const [redirectUrl,setRedirectUrl] = useState("#");
    const [visitorParameters, setVisitorParameters]     =   useState({});
    const { getUserAgent} = useUserAgent();
    const { getRedirectionUrl} = useRedirection();
    const { saveDataIngestion} = useDataIngestion();
    const [showCookie,setshowCookie] = useState('d-block');
    var cookieName = "CookiesPolicyHide";

    useEffect(() => {
        (async () =>{
            const response = await getUserAgent(
                window.navigator.userAgent,
                "AP",
                queryString,
                EnvConstants.AppDomain,
                window.location.href
            )
            const visitorData = response.data.response.visitor_parameters;
            const DataParams = response.data.response.data;
            setVisitorParameters({visitor_parameters:visitorData,data:DataParams,date:new Date(),pageName:"2021/05/plevin/ppi_OPC_V1"});
            const visparam = {
                country:DataParams.country,
                device:DataParams.site_flag_id,
                siteFlagId:DataParams.site_flag,
                browser:DataParams.browser,
                platform:DataParams.platform,
                uuid:visitorData.uuid
            };
            const responseRedirect = await getRedirectionUrl(
                visparam,
                window.location.href,
                EnvConstants.AppDomain,
                "2021/05/plevin/ppi_OPC_V1"
            )
            await saveDataIngestion(
                "adv_page_load",
                visitorData,
                DataParams,
                "2021/05/plevin/ppi_OPC_V1"
            )
            if(responseRedirect.data.status==='success'){
                setRedirectUrl(responseRedirect.data.response.data.redirect_url);
            }else{
                setRedirectUrl("#");
            }
        })();
    }, []);

    const cookieClose = (e) => {
        e.preventDefault();
        setshowCookie('d-none');
        if(cookieName != null){
            var d = new Date();
            var cvalue = "Yes";
            d.setTime(d.getTime() + (1*24*60*60*1000));
            var expires = "expires="+d.toUTCString();
            document.cookie = cookieName + "=" + cvalue + "; " ;
            setshowCookie('d-none');
        }
    }
    return (
        <>
        <VisitorParamsProvider value={{ visitorParameters  }}>
            <RedirectProvider value={{ redirectUrl:redirectUrl }}>
                <div>
                    <AdvHeader Style={Adv1Style}/>
                    <section>
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <h1>Missed the PPI Deadline?  You Could Get Up To £40,000 In Compensation!*</h1>
                            <h2>More Payouts Coming For Millions of Customers in ‘PPI2’ Storm!</h2>
                        </div>
                        <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                            <Anchor dataId="PARA_IMAGE_1">
                                <img src="/PPI_OPC_V1/img/banner.png" alt="" className={Adv1Style.banner}/>
                            </Anchor>
                            <div className={Adv1Style.liness}>
                                <div className={Adv1Style.yellows}>
                                    <p><b><Anchor dataId="PARA_TEXT_LINKS_1">Millions of customers could be eligible for £1,000s in compensation following a court ruling on hidden commission.</Anchor></b></p>
                                </div>
                            </div>
                            
                            <h3>Have You Been Plevined?</h3>
                            <p>The PPI deadline may have passed in August 2019 but you could still be eligible to claim £1,000s in PPI compensation!</p>
                            <p>Unknown to many, a Supreme Court ruling from 2014 opened up a new claiming route that allows millions of people to submit new claims even though the PPI claims deadline has passed.</p>
                            <p>A named Susan Plevin became aware that 71.8% of the premiums she paid to her PPI policy went to a commission payment for her lender, she took her complaint all the way to the Supreme Court. The Supreme Court decided that the relationship between the lender and Mrs. Plevin was ‘unfair’ because the <a href="#"> high amount of commission included in the cost of the policy was never made clear</a> to her. Years later, the FCA took this ruling and began applying it to new PPI complaints.</p>
                            <Anchor dataId="PARA_TEXT_LINKS_1"><button type="button" className={Adv1Style.boxer}>
                                <h3>The Plevin Rule</h3>
                                <p>The Plevin rule states if more than 50% of your PPI payments were made in the form of commission and this was not explained to you at the time, you could claim back payments above that threshold, plus interest.</p>
                            </button></Anchor>
                            <p><Anchor dataId="PARA_TEXT_LINKS_1"> It's said that banks were taking excessive commissions between 70 and 95 per cent!** 
                                </Anchor> This means that millions of people who had PPI, even if it was sold correctly in other ways, are still entitled to claim something back.</p>
                            <div className={Adv1Style.cta_sec_dsk}>
                            <div className="row">
                                <AdvBanner
                                    textHead="HOW TO GET STARTED:"
                                    texSubHead="Select Your Lender to Start Your Claim"
                                    bannerLenders={BannerLendersCP_V1}
                                    Style={Adv1Style}
                                />
                                    
                                </div>
                            </div>
                            <p>Even if you tried to claim PPI money in the past and the bank rejected you, you could try again!</p>
                            <h3>Are You Eligible To Claim For PPI2?</h3>
                            <ul>
                                <li>You won your PPI claim but it was not the total amount you paid for PPI</li>
                                <li>You had PPI but never made a claim.</li>
                                <li>You’re unsure if you’ve had PPI but could use some extra money in your pocket right about now.</li>
                            </ul>
                            <p>If you answered yes to any of the above questions <Anchor dataId="PARA_TEXT_LINKS_2"> this could mean thousands of pounds in your pocket!</Anchor></p>
                            <h3>How Do I Submit A Claim?</h3>
                            <p>Before starting the claims process, it’s important to know you are not eligible for a refund if you have already successfully claimed your full PPI refund.</p>
                            <p>If you haven’t tried to submit a claim yet, it’s worth checking if you’re entitled to compensation! Follow the steps below to start your claim today.</p>
                            <p><b>Step 1:</b> Select your country on the map below</p>
                            <p><b>Step 2:</b> Answer a few questions to <Anchor dataId="PARA_TEXT_LINKS_2"> start your Plevin claim!</Anchor></p>
                            
                            <AdvMap
                                    textHead="HOW TO GET STARTED:"
                                    texSubHead="Click Your Country on the Map Below to Get Started"
                                    Style={Adv1Style}
                                />
                            <div className="col-12 text-center">
                            <Anchor dataId="MAIN_CTA_BTN" className={`${Adv1Style.green_btn} btn`}>
                            START MY CLAIM</Anchor>
                            </div>
                        </div>
                        <AdvSidebar
                                texSubHead="Select Your Lender to Start Your Claim"
                                bannerLenders={BannerLendersCP_V1}
                                Style={Adv1Style}
                            />
                        </div>
                    </div>
                    </section>
                    <AdvFooter Style={Adv1Style}/>
                    <div id="cookieBar" className={`${Adv1Style.cooki} text-center ${showCookie}`}>
                        <div className="col-lg-12 col-md-12">
                            <big>
                                <span>Cookies help us deliver our services. By using this website you agree to use of our cookies. </span><a href="javascript:;" rel="noreferrer" data-toggle="modal" data-target=".CookiePopup"><u>Learn More.</u></a> 
                            </big>
                            <a href=""  id="cookclose" data-cookiename={cookieName} className={Adv1Style.close} onClick={cookieClose}>Close <i className="fa fa-close"></i></a>
                        </div>
                    </div>
                </div>
            </RedirectProvider>
            </VisitorParamsProvider>
            <CookiesModal/>
        </>
    )
}
export default Adv1;