import React from "react";
import { BrowserRouter,Route } from "react-router-dom";
import Adv1 from './Components/Pages/Adv1';
import Contact from './Components/Pages/Contact';
import PrivacyPolicy from './Components/Pages/PrivacyPolicy';
import CookiesPolicy from './Components/Pages/CookiesPolicy';
import FbPixel from './Components/Pages/FbPixel';
import GoogleTag from './Components/Includes/GoogletagManager';
import FbViewPixel from './Components/Includes/FbViewPixel';
import "./App.scss";

const App = () => {
  return (
    <>
      <GoogleTag/>
      <FbViewPixel/>
      <BrowserRouter>
        <Route exact path={["/","/adv1","/2021/05/plevin/ppi_OPC_V1"]} component={Adv1} />
        <Route exact path={"/contact"} component={Contact} />
        <Route exact path={"/cookiespolicy"} component={CookiesPolicy} />
        <Route exact path={"/privacypolicy"} component={PrivacyPolicy} />
        <Route exact path="/fbpixel" component={FbPixel} />
      </BrowserRouter>
    </>
  );
};
export default App;
