import React from 'react';
import Anchor from "../../UI/Anchor";

const AdvMap     =   ({textHead,texSubHead,Style}) =>{
    return (
      <>
      <div className={Style.cta_sec_dsk}>
                     <div className="row">
                         <div className={`col-lg-12 col-xs-12 ${Style.banner11} text-center`}>
                             <h2>{textHead}</h2>
                             <p>{texSubHead}</p>
                             <Anchor dataId="MAIN_CTA_MAP"> <img src="/PPI_OPC_V1/img/map.png" alt="" className="mt-2 map_image"/> </Anchor>
                         </div>
                     </div>
        </div>
      </>
    )
}
export default AdvMap;
