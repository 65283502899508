import React from 'react';
import Anchor from "../../UI/Anchor";
import PrivacyPolicyModal from "../Layouts/PrivacyPolicyModal";
import CookiesModal from "../Layouts/CookiesModal";

const AdvFooter     =   ({Style}) =>{    
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className={Style.pb}>
                            <p>DISCLAIMER: The material on this website is intended only to provide a summary and general overview on matters of interest.</p>
                            <p>SOURCES</p>
                            <p><a className="rp_clk" href="https://www.mirror.co.uk/money/millions-customers-can-now-make-23940044" target="_blank" rel="noreferrer">*Millions more customers can now make new PPI claims - could you get up to £40,000? - Mirror Online</a></p>
                            <p><a className="rp_clk" href="https://www.thesun.co.uk/money/14681126/millions-of-customers-new-ppi-claims-bank-ruling/" target="_blank" rel="noreferrer">**Millions of customers can make new PPI claims for up to £40,000 after crucial court ruling (thesun.co.uk)</a></p>
                        </div>
                        <div className={`col-12 ${Style.end_footer}`}>
                            <div className="row m-0">
                                <div className="col-lg-3 col-md-3 col-sm-4 col-12 text-center">
                                <Anchor dataId="SITE_LOGO" className="btn_optimize"> <img src="/PPI_OPC_V1/img/logo.png" alt="logo"/> </Anchor>
                                </div>
                                <div className="col-lg-9 col-md-9 col-sm-8 col-12">
                                    <ul>
                                        <li><a href="javascript:;" className="rp_clk" rel="noreferrer" data-toggle="modal" data-target=".PrivacyPopup">Privacy Policy</a></li>
                                        <li><a href="javascript:;" className="rp_clk" rel="noreferrer" data-toggle="modal" data-target=".CookiePopup">Cookie Policy</a></li>
                                        <li><a href={"/contact"} className="rp_clk" target="_blank">Contact Us</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <PrivacyPolicyModal/>
            <CookiesModal/>
         </>
    )
}
export default AdvFooter;
