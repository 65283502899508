import React from 'react';
import Anchor from "../../UI/Anchor";
const AdvHeader     =   ({heading,Style}) =>{
    const HeadString =  (heading)? heading: 'Married Couples Are Getting Up To £1,188 In Tax Breaks – Here’s How To Join Them';
    return (
        <>
        <header className={Style.adv1_header}>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                <Anchor dataId="SITE_LOGO"><img src="/PPI_OPC_V1/img/logo.png" alt="" className={Style.logo}/> </Anchor>
                 </div>
                 <div className="col-lg-8 col-md-8 col-sm-8 col-6 d-none d-sm-block d-md-block d-lg-block bnr_top">
                 <Anchor dataId="TOP_SITE_BANNER"><img src="/PPI_OPC_V1/img/rbanner.gif" className="t_bnr img-fluid"/></Anchor>
                 </div>
            </div>
         </div>
        </header>
    </>
    )
}
export default AdvHeader;
